import React from 'react';
import Layout from '../components/Layout.js'
import { Box, Grid, Typography } from '@material-ui/core';
import Button from '../components/Button';
import * as ROUTES from '../constants/routes';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';

const browser = typeof window !== "undefined" && window;

const useStyles = makeStyles(() => ({
  text: {
    margin: '20px 0'
  }
}));

const translations = {
  pageTitle: 'Page not found',
  titles: {
    main: 'Page not found',
    subtitle: '404 - The page you are looking for doesn\'t exist.',
  },
  buttons: {
    home: 'Home page',
  },
};

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    browser && (<Box m={2}>
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <Typography className={classes.text} variant="h2">{translations.titles.main}</Typography>
          <Typography className={classes.text}  variant="h5">
            {translations.titles.subtitle}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Button
            className={classes.button}
            to={ROUTES.LANDING}
            color="primary"
            variant='contained'
          >
            {translations.buttons.home}
          </Button>
        </Grid>
      </Grid>
    </Box>)
  );
};

const NotFound = () => (
  <Layout>
    <Helmet>
      <title>{translations.pageTitle}</title>
    </Helmet>
    <NotFoundPage />
  </Layout>
);

export default NotFound;
